import React, { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import useFormal from '@kevinwolf/formal-web'
import * as yup from 'yup'
import { useApi } from 'components/providers/ApiProvider'
import { useParkingPayments } from 'ParkingPayments/ParkingPaymentsProvider'
import { useMessage } from 'components/elements/Message'
import { DraggableDialog, DraggableDialogTitle } from 'components/elements/DraggableDialog'
import { useTranslation } from 'react-i18next'
import { TextField } from 'components/elements/TextField'

export interface RefundPaymentDialogData {
  payment_id: number
}

type Props = {
  open: boolean
  data: RefundPaymentDialogData | null
}
type Functions = {
  setOpen(value: boolean): void
}
type AllProps = Props & Functions

const RefundPaymentDialog: FunctionComponent<AllProps> = ({ open, setOpen, data }) => {
  if (!data) {
    return null
  }

  const { t } = useTranslation()
  const api = useApi()
  const { setLoadList } = useParkingPayments()
  const { showSuccess, showError } = useMessage()

  const handleEnter = () => {
    formal.change('amount', (0).toFixed(2))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    formal.change('amount', initialValues.amount)
    formal.clearErrors()
  }

  const initialValues = {
    amount: 0,
  }

  const schema = yup.object().shape({
    amount: yup.number().required().test({
      test: value => {
        return Number.isInteger(value * (10 ** 2))
      },
    }),
  })

  const formal = useFormal(initialValues, {
    schema,
    onSubmit: values => {
      values = schema.cast(values)

      api.refundParkingPayment({
        payment_id: data.payment_id,
        amount: Math.round(values.amount * 100),
      })
        .then(data => {
          if (!data || !data.status) {
            throw new Error
          }

          handleClose()

          setLoadList()

          showSuccess(t('messages.paymentRefunded'))
        })
        .catch(error => {
          let message

          switch (error.code) {
            case 3:
              message = t('alerts.checkInput')
              break
            case 15:
              message = t('alerts.paymentIsAttachedToParkingSession')
              break
            default:
              message = t('alerts.tryLater')
          }

          showError(`${t('alerts.couldNotRefundPayment')} ${message}`)
        })
    },
  })

  return (
    <DraggableDialog fullWidth open={open} onEnter={handleEnter} onClose={handleClose} onExited={handleExited}>
      <DraggableDialogTitle>{t('refundPayment')}</DraggableDialogTitle>
      <form {...formal.getFormProps()} noValidate>
        <DialogContent>
          <Grid container>
            <TextField
              GridProps={{ xs: 12 }}
              label={t('amount')}
              required
              {...formal.getFieldProps('amount')}
              autoComplete="off"
              autoFocus={true}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formal.isSubmitting}
          >
            {t('refund')}
          </Button>
        </DialogActions>
      </form>
    </DraggableDialog>
  )
}

export default RefundPaymentDialog
